// import DiFA1rep from '../assets/pdp/DiFA1rep.jpg'
// import DiFA2rep from '../assets/pdp/DiFA2rep.jpg'
// import DiFA3rep from '../assets/pdp/DiFA3rep.jpg'
import DiFB1rep from '../assets/pdp/DiFB1rep.jpg'
import DiFB2rep from '../assets/pdp/DiFB2rep.jpg'
//goa
import Acacia1 from '../../src/assets/pdp/floor-plan/goa/Acacia1.webp'
import Acacia2 from '../../src/assets/pdp/floor-plan/goa/Acacia2.webp'
import Acacia3 from '../../src/assets/pdp/floor-plan/goa/Acacia3.webp'
import Allamanda1 from '../../src/assets/pdp/floor-plan/goa/Allamanda1.webp'
import Allamanda2 from '../../src/assets/pdp/floor-plan/goa/Allamanda2.webp'
import Allamanda3 from '../../src/assets/pdp/floor-plan/goa/Allamanda3.webp'
import Alstonia1 from '../../src/assets/pdp/floor-plan/goa/Alstonia1.webp'
import Alstonia2 from '../../src/assets/pdp/floor-plan/goa/Alstonia2.webp'
import Alstonia3 from '../../src/assets/pdp/floor-plan/goa/Alstonia3.webp'
import Russelia1 from '../../src/assets/pdp/floor-plan/goa/Russelia1.webp'
import Russelia2 from '../../src/assets/pdp/floor-plan/goa/Russelia2.webp'
import Russelia3 from '../../src/assets/pdp/floor-plan/goa/Russelia3.webp'

//astrum
import AsF1rep from '../assets/pdp/AsF1rep.jpg'
import AsF2rep from '../assets/pdp/AsF2rep.jpg'
import AsF3rep from '../assets/pdp/AsF3rep.jpg'
//avanya
import AvF1rep from '../../src/assets/pdp/floor-plan/avanya/AvF1rep.webp'
import AvF2rep from '../../src/assets/pdp/floor-plan/avanya/AvF2rep.webp'
import AvF3rep from '../../src/assets/pdp/floor-plan/avanya/AvF3rep.webp'
import AvF4rep from '../../src/assets/pdp/floor-plan/avanya/AvF4rep.webp'
import AvF5rep from '../../src/assets/pdp/floor-plan/avanya/AvF5rep.webp'
import AvF6rep from '../../src/assets/pdp/floor-plan/avanya/AvF6rep.webp'
//divum
import DiFA1rep from '../../src/assets/pdp/DiFA1rep.jpg'
import DiFA2rep from '../../src/assets/pdp/DiFA2rep.jpg'
import DiFA3rep from '../../src/assets/pdp/DiFA3rep.jpg'
// import DiFB1rep from '../../src/assets/pdp/DiFB1rep.jpg'
// import DiFB2rep from '../../src/assets/pdp/DiFB2rep.jpg'
// import DiFB3rep from '../../src/assets/pdp/DiFB3rep.jpg'
//luma
import LuFPWiA1rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiA1rep.webp'
import LuFPWiA2rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiA2rep.webp'
import LuFPWiA3rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiA3rep.webp'
import LuFPWiB1rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiB1rep.webp'
import LuFPWiB2rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiB2rep.webp'
import LuFPWiB3rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiB3rep.webp'
import LuFPWiC1rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiC1rep.webp'
import LuFPWiC2rep from '../../src/assets/pdp/floor-plan/luma/LuFPWiC2rep.webp'
//parkwood
import PaF1rep from '../../src/assets/pdp/PaF1rep.webp'
import PaF2rep from '../../src/assets/pdp/PaF2rep.jpg'
import PaF3rep from '../../src/assets/pdp/PaF3rep.jpg'
import PaF4rep from '../../src/assets/pdp/PaF4rep.jpg'

//srishti
import SrF1rep from '../../src/assets/pdp/Srishti-Namaah-Tower-E-Plan-Booklet_V310.webp'
import SrF2rep from '../../src/assets/pdp/Srishti-Namaah-Tower-E-Plan-Booklet_V313.webp'
import SrF3rep from '../../src/assets/pdp/Srishti-Namaah-Tower-E-Plan-Booklet_V316.webp'
// import SrF4rep from '../../src/assets/pdp/SrF4rep.jpg'
// import SrF5rep from '../../src/assets/pdp/SrF5rep.jpg'
// import SrF6rep from '../../src/assets/pdp/SrF6rep.jpg'


//rate
import raet1 from '../../src/assets/pdp/raet/typical-floor-plan.webp'
import raet2 from '../../src/assets/pdp/raet/flatno.1-4bhk.jpg'
import raet3 from '../../src/assets/pdp/raet/flatno.1penthoselower-lvl.jpg'
import raet4 from '../../src/assets/pdp/raet/flatno.1-penthouseupperlvl.jpg'
import raet5 from '../../src/assets/pdp/raet/flatno.2-4bhk.jpg'


//rate
import amnenities1 from '../../src/assets/pdp/amenities-feature/flatno.1-2bhk.jpg'
import amnenities2 from '../../src/assets/pdp/amenities-feature/flatno.2-3bhk.jpg'
import amnenities3 from '../../src/assets/pdp/amenities-feature/master-plan.jpg'


export const ourFloorData = [
  {
    src: DiFA1rep,
    title: 'WING A'
  },
  {
    src: DiFA2rep,
    title: 'WING A 3-BHK'
  },
  {
    src: DiFA3rep,
    title: 'WING A 2-BHK Lux'
  },
  {
    src: DiFB1rep,
    title: 'WING A 3-BHK'
  },
  {
    src: DiFB2rep,
    title: 'WING A 2-BHK Lux'
  }
];
//goa
export const ourFloorDatagoa = [
  {
    src: Acacia1,
    title: 'ACACIA BASEMENT'
  },
  {
    src: Acacia2,
    title: 'ACACIA GROUND FLOOR'
  },
  {
    src: Acacia3,
    title: 'ACACIA FIRST FLOOR'
  },
  {
    src: Allamanda1,
    title: 'ALLAMANDA BASEMENT'
  },  {
    src: Allamanda2,
    title: 'ALLAMANDA GROUND FLOOR'
  },  {
    src: Allamanda3,
    title: 'ALLAMANDA FIRST FLOOR'
  },  {
    src: Alstonia1,
    title: 'ALSTONIA BASEMENT'
  },  {
    src: Alstonia2,
    title: 'ALSTONIA GROUND FLOOR'
  },  {
    src: Alstonia3,
    title: 'ALSTONIA FIRST FLOOR'
  },  {
    src: Russelia1,
    title: 'RUSSELIA BASEMENT'
  },  {
    src: Russelia2,
    title: 'RUSSELIA GROUND FLOOR'
  },  {
    src: Russelia3,
    title: 'RUSSELIA FIRST FLOOR'
  },

];
//astrum
export const ourFloorDataastrum = [
  {
    src: AsF1rep,
    title: 'TYPICAL FLOOR PLAN'
  },
  {
    src: AsF2rep,
    title: '2 BHK'
  },
  {
    src: AsF3rep,
    title: '3 BHK'
  },

];
//avanya
export const ourFloorDataavanya = [
  {
    src: AvF5rep,
    title: 'TYPICAL PLAN TOWER A'
  },
  {
    src: AvF4rep,
    title: 'TYPICAL PLAN TOWER B'
  },

  {
    src: AvF6rep,
    title: 'TYPICAL PLAN TOWER C'
  },
  {
    src: AvF1rep,
    title: '1 BHK'
  },
  {
    src: AvF2rep,
    title: '2 BHK'
  },
  {
    src: AvF3rep,
    title: '2 BHK Grande'
  },
];
//divum
export const ourFloorDatadivum = [
  {
    src: DiFA1rep,
    title: 'TYPICAL FLOOR PLAN'
  },
  {
    src: DiFA2rep,
    title: '2 BHK LUXE'
  },
  {
    src: DiFA3rep,
    title: '2 BHK GRANDE'
  },
  // {
  //   src: DiFB1rep,
  //   title: 'WING B'
  // },
  // {
  //   src: DiFB2rep,
  //   title: 'WING B 3-BHK'
  // },
  // {
  //   src: DiFB3rep,
  //   title: 'WING B 2-BHK Lux'
  // },
];
//luma
export const ourFloorDataluma = [

  {
    src: LuFPWiA1rep,
    title: 'Typical Floor Plan Wing A'
  },
  {
    src: LuFPWiB1rep,
    title: 'Typical Floor Plan Wing B'
  },

  {
    src: LuFPWiC1rep,
    title: 'Typical Floor Plan Wing C'
  },
  {
    src: LuFPWiA2rep,
    title: '2 BHK Luxe Wing A'
  },
  {
    src: LuFPWiA3rep,
    title: '3 BHK Wing A'
  },
  {
    src: LuFPWiB2rep,
    title: '2 BHK Luxe Wing B'
  },
  {
    src: LuFPWiB3rep,
    title: '3 BHK Wing B'
  },
  {
    src: LuFPWiC2rep,
    title: '2 BHK Luxe Wing C'
  },



];
//parkwood
export const ourFloorDataparkwood = [
  {
    src: PaF1rep,
    title: 'TYPICAL FLOOR PLAN D5'
  },
  {
    src: PaF4rep,
    title: 'TYPICAL FLOOR PLAN D6'
  },
  {
    src: PaF2rep,
    title: 'FLAT NO 01'
  },
  {
    src: PaF3rep,
    title: 'FLAT NO 03'
  },
  

];
//srishti
export const ourFloorDatasrishti = [
  {
    src: SrF1rep,
    title: '2 BHK'
  },
  {
    src: SrF2rep,
    title: '3 BHK'
  },
  {
    src: SrF3rep,
    title: '4 BHK'
  },
  // {
  //   src: SrF4rep,
  //   title: '1 BHK PRIMA B'
  // },
  // {
  //   src: SrF5rep,
  //   title: '2 BHK PREMIA A'
  // },
  // {
  //   src: SrF6rep,
  //   title: '2 BHK PREMIA A'
  // },
];
//raet
export const ourFloorDataraet = [
  {
    src: raet1,
    title: 'Typical Floor Plans'},
  {
    src: raet2,
    title: 'Flat No. 1 (4 BHK)'
  },
  {
    src: raet3,
    title: 'Flat No. 1 ( Penthose Lower LVL)'
  },
  {
    src: raet4,
    title: 'Flat No. 1 (Penthouse Upper Lvl)'
  },
  {
    src: raet5,
    title: 'Flat No. 2 (4 BHK)'
  },

];
//raet
export const ourFloorDataamenitiesfeature = [
  {
    src: amnenities3,
    title: 'Master Plan'
  },
  {
    src: amnenities1,
    title: 'Flat No. 1 (2 BHK)'
  },
  {
    src: amnenities2,
    title: 'Flat No. 2 (3 BHK)'
  },

];