import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import SG1rep from "../../../../assets/gallery/Elevation-Front-Angle.webp";
import SG2rep from "../../../../assets/gallery/Crown-Twlight-Shot.webp";
import SG3rep from "../../../../assets/gallery/SG3rep.webp";
import SG4rep from "../../../../assets/gallery/SG4rep.webp";
import SG5rep from "../../../../assets/gallery/SG5rep.webp";
import SG6rep from "../../../../assets/gallery/SG6rep.webp";
import SG7rep from "../../../../assets/gallery/SG7rep.webp";
import SG8rep from "../../../../assets/gallery/SG8rep.webp";
// Stylesheet
import "./gallerysrishti.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}




const Collage = () => {
  return (
    <section className="gallerycollapsesrishtinamaah">

<Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >

      <>
        <div className="gallary_containersrishtinamaah">
          <div className="gallary_item grid_row_span_2">
          <a data-fancybox="gallery" href={SG1rep}>
            <img src={SG1rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION-FRONT-ANGLE</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
          <a data-fancybox="gallery" href={SG2rep}>
            <img src={SG2rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">CROWN TWLIGHT SHOT</span>
            </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={SG3rep}>
            <img src={SG3rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">RETAIL</span>
            </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={SG4rep}>
            <img src={SG4rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">ELEVATION FRONT VIEW</span>
            </div>
            </a>
          </div>
          <div className="gallary_item grid_row_span_2">
          <a data-fancybox="gallery" href={SG5rep}>
            <img src={SG5rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">PODIUM</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
          <a data-fancybox="gallery" href={SG6rep}>
            <img src={SG6rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">CLUB HOUSE</span>
            </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={SG7rep}>
            <img src={SG7rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">LANDSCAPE GARDEN</span>
            </div>
            </a>
          </div>
          <div className="gallary_item ">
          <a data-fancybox="gallery" href={SG8rep}>
            <img src={SG8rep} alt="gallery img" />
            <div class="overlay">
              <span className="hover-text">SWIMMING POOL</span>
            </div>
            </a>
          </div>
        </div>
      </>
      </Fancybox>
    </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE  SRISHTI NAMAAH</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}

      </Layout>
    </section>

  );
};


export default Gallery;
