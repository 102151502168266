import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Luma-Overview2.webp";
// Stylesheet
import "./landmarkluma.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkluma' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>The Future of Luxury Today</h2>
                    <img src={LandmarkImg} alt="landmark img" />
                </div>
                <div>
                    <h4>Designed to reflect the resplendent luxury that defines Andheri's tomorrow, Luma is a beacon that shines bright. Enveloped by magnificence as soon as you step in, the towers offer breathtaking views of the city in all its glory. All that's left is for you to call it your new home.</h4>
                    <p>They say homes should be reflective of the lifestyle. That mantra truly comes alive at Luma. Designed for the modern-day go-getter. All work. All play. A life that you’ve always dreamt of, is what makes Luma an object of desire. It offers the plushest 2 BHKs and 3 BHKs that will set a benchmark of luxury for the years to come. Luma has been designed for the future, today.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
