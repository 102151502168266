import { useState } from 'react';
import { motion } from 'framer-motion';

// Components
import Layout from '../../layout/Layout/Layout';
import ButtonPrimary from '../button/ButtonPrimary';
import { textAnimate } from '../home/projects/Projects';

import Astrum from '../../../assets/project-brochures/Astrum.pdf'
import Avanya from '../../../assets/project-brochures/Avanya.pdf'
import Divum from '../../../assets/project-brochures/Divum.pdf'
import Luma from '../../../assets/project-brochures/Luma.pdf'
import Parkwoods from '../../../assets/project-brochures/Parkwoods.pdf'
import Srishti from '../../../assets/project-brochures/Srishti-Tower-J-brochure.pdf'
import AldeiaGoa from '../../../assets/project-brochures/Aldeia-Goa.pdf'
import Raet from '../../../assets/project-brochures/Raet.pdf'
import Residencies from '../../../assets/project-brochures/Residencies.pdf'
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// Stylesheet
import './contact.scss';

const Contact = () => {


  const location = useLocation();


  const pathArray = location.pathname.split('/').filter((a) => a);
  const [project, setProject] = useState(pathArray[1] ? pathArray[1].charAt(0).toUpperCase() + pathArray[1].slice(1) : '');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [flag, setFloag] = useState(true);
  const [country, setCountry] = useState("");

  const onChangeTagInput = (value) => {
    return value.replace(/[^a-zA-Z\d ]/ig, "");
  }
  const onChangeTagInputNumber = (value) => {
    return value.replace(/[^0-9.]/g, '');
  }




  const callInquiryAPI = async () => {
    try {
      const res = await axios.post('https://api.dynamixgroup.co.in/api/v1' + '/contactus', {
        enquiryMap: {
          "Stage__c": "New Enquiry",
          "ProjectId": project,
          "Enquiry_Source__c": "Website",
          "Enquiry_Sub_Source__c": "",
          "EnquiryId": "",
          "UTM_Campaign__c": "",
          "UTM_medium__c": "",
          "UTM_Parameters__c": "",
          "UTM_Source__c": "",
          "Configuration__c": "1 BHK",
          "Site_Visit_Scheduled_Date__c": "",
          "Campaign__c": ""
        },
        contactMap: {
          "LastName": lastName,
          "FirstName": firstName,
          "Salutation": "Mr.",
          "Country_Code__c": "+91",
          "PersonMobilePhone": phone,
          "Email__c": email
        }
      }
      );

    } catch (err) {
      console.log(':: err blog list ', err)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errordiv = document.getElementById('msg-error');
    const successdiv = document.getElementById('msg-success');
    const formContainer = document.getElementById('register-form');

    let errors = [];

    if (firstName.length < 3) {
      errors.push("First name should have minimum 3 characters.");
    }
    if (lastName.length < 3) {
      errors.push("Last name should have minimum 3 characters.");
    }
    if (phone.length != 10) {
      errors.push("Phone number should have 10 digits.");
    }
    if (document.querySelector('#email').value.length < 3) {
      errors.push("Email is required.");
    }
    if (!document.querySelector('#tnc').checked) {
      errors.push("Please check the Disclaimer box.");
    }

    var selectedProject = Divum;
    switch (project) {
      case 'Astrum':
        selectedProject = Astrum;
        break;
      case 'Avanya':
        selectedProject = Avanya;
        break;
      case 'Divum':
        selectedProject = Divum;
        break;
      case 'Luma':
        selectedProject = Luma;
        break;
      case 'Parkwoods':
        selectedProject = Parkwoods;
        break;
      case 'Srishtinamaah':
        selectedProject = Srishti;
        break;
      case 'Aldeiadegoa':
        selectedProject = AldeiaGoa;
        break;
      case 'Raet':
        selectedProject = Raet;
        break;
      case 'Residencies':
        selectedProject = Residencies;
        break;
      default:
        selectedProject = Divum;
        break;

    }



    if (errors.length) {
      var html = '<ul>';
      errors.forEach((item, index) => {
        html += '<li class="error_item">' + item + '</li>';
      });
      html += '</ul>';
      errordiv.innerHTML = html
      successdiv.innerHTML = '';
    } else {
      errordiv.innerHTML = '';
      successdiv.innerHTML = 'Thank you for reaching out. Our team will respond to you shortly. <br /> Download brochure <a href="' + selectedProject + '">Here</a>';

      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");

      callInquiryAPI();

    }



  }






  return (

    <section className="contact" id='Brochure'>

      <Layout>
        <div className="contact_container">
          <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
            <motion.h2 variants={textAnimate}>Contact Us</motion.h2>
            <p>We’re happy to help you, just write us an email or call us.</p>
          </motion.div>
          <div className="input_container">
            <form onSubmit={handleSubmit} id="register-form" className="new-form" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D5g000002IVBy" method="POST">
              <input type="hidden" name="oid" value="00D5g000002IVBy" />
              <input type="hidden" name="retURL" value="/thankyou.php" />
              <div className="input_wrapper">
                <div className="col-md-12 col-md-6 col-xs-12">
                  <input pattern="^[a-zA-Z0-9]+$" value={firstName} onChange={(e) => setFirstName(onChangeTagInput(e.target.value))} id="first_name" maxlength="40" name="first_name" size="20" type="text" placeholder="First Name*" />
                </div>
                <div className="col-md-12 col-md-6 col-xs-12">
                  <input pattern="^[a-zA-Z0-9]+$" value={lastName} onChange={(e) => setLastName(onChangeTagInput(e.target.value))} id="last_name" maxlength="80" name="last_name" size="20" type="text" placeholder="Last Name*" />
                </div>
                <div className="col-md-3 col-lg-2 col-xs-4 pad-r0">
                  {/* <!-- Country Code    --> */}
                  <select onChange={(e) => setCountry(e.target.value)} id="00NC5000000WkkI" name="00NC5000000WkkI" title="Country Code">
                    <option value="">--None--</option>
                    <option value="+1">+1</option>
                    <option value="+7">+7</option>
                    <option value="+20">+20</option>
                    <option value="+27">+27</option>
                    <option value="+30">+30</option>
                    <option value="+31">+31</option>
                    <option value="+32">+32</option>
                    <option value="+33">+33</option>
                    <option value="+34">+34</option>
                    <option value="+36">+36</option>
                    <option value="+39">+39</option>
                    <option value="+40">+40</option>
                    <option value="+41">+41</option>
                    <option value="+43">+43</option>
                    <option value="+44">+44</option>
                    <option value="+45">+45</option>
                    <option value="+46">+46</option>
                    <option value="+47">+47</option>
                    <option value="+48">+48</option>
                    <option value="+49">+49</option>
                    <option value="+51">+51</option>
                    <option value="+52">+52</option>
                    <option value="+53">+53</option>
                    <option value="+54">+54</option>
                    <option value="+55">+55</option>
                    <option value="+56">+56</option>
                    <option value="+57">+57</option>
                    <option value="+58">+58</option>
                    <option value="+60">+60</option>
                    <option value="+61">+61</option>
                    <option value="+62">+62</option>
                    <option value="+63">+63</option>
                    <option value="+64">+64</option>
                    <option value="+65">+65</option>
                    <option value="+66">+66</option>
                    <option value="+81">+81</option>
                    <option value="+82">+82</option>
                    <option value="+84">+84</option>
                    <option value="+86">+86</option>
                    <option value="+90">+90</option>
                    <option value="+91" selected>+91</option>
                    <option value="+92">+92</option>
                    <option value="+93">+93</option>
                    <option value="+94">+94</option>
                    <option value="+95">+95</option>
                    <option value="+98">+98</option>
                    <option value="+211">+211</option>
                    <option value="+212">+212</option>
                    <option value="+213">+213</option>
                    <option value="+216">+216</option>
                    <option value="+218">+218</option>
                    <option value="+220">+220</option>
                    <option value="+221">+221</option>
                    <option value="+222">+222</option>
                    <option value="+223">+223</option>
                    <option value="+224">+224</option>
                    <option value="+225">+225</option>
                    <option value="+226">+226</option>
                    <option value="+227">+227</option>
                    <option value="+228">+228</option>
                    <option value="+229">+229</option>
                    <option value="+230">+230</option>
                    <option value="+231">+231</option>
                    <option value="+232">+232</option>
                    <option value="+233">+233</option>
                    <option value="+234">+234</option>
                    <option value="+235">+235</option>
                    <option value="+236">+236</option>
                    <option value="+237">+237</option>
                    <option value="+238">+238</option>
                    <option value="+239">+239</option>
                    <option value="+240">+240</option>
                    <option value="+241">+241</option>
                    <option value="+242">+242</option>
                    <option value="+243">+243</option>
                    <option value="+244">+244</option>
                    <option value="+245">+245</option>
                    <option value="+246">+246</option>
                    <option value="+248">+248</option>
                    <option value="+249">+249</option>
                    <option value="+250">+250</option>
                    <option value="+251">+251</option>
                    <option value="+252">+252</option>
                    <option value="+253">+253</option>
                    <option value="+254">+254</option>
                    <option value="+255">+255</option>
                    <option value="+256">+256</option>
                    <option value="+257">+257</option>
                    <option value="+258">+258</option>
                    <option value="+260">+260</option>
                    <option value="+261">+261</option>
                    <option value="+262">+262</option>
                    <option value="+263">+263</option>
                    <option value="+264">+264</option>
                    <option value="+265">+265</option>
                    <option value="+266">+266</option>
                    <option value="+267">+267</option>
                    <option value="+268">+268</option>
                    <option value="+269">+269</option>
                    <option value="+290">+290</option>
                    <option value="+291">+291</option>
                    <option value="+297">+297</option>
                    <option value="+298">+298</option>
                    <option value="+299">+299</option>
                    <option value="+350">+350</option>
                    <option value="+351">+351</option>
                    <option value="+352">+352</option>
                    <option value="+353">+353</option>
                    <option value="+354">+354</option>
                    <option value="+355">+355</option>
                    <option value="+356">+356</option>
                    <option value="+357">+357</option>
                    <option value="+358">+358</option>
                    <option value="+359">+359</option>
                    <option value="+370">+370</option>
                    <option value="+371">+371</option>
                    <option value="+372">+372</option>
                    <option value="+373">+373</option>
                    <option value="+374">+374</option>
                    <option value="+375">+375</option>
                    <option value="+376">+376</option>
                    <option value="+377">+377</option>
                    <option value="+378">+378</option>
                    <option value="+379">+379</option>
                    <option value="+380">+380</option>
                    <option value="+381">+381</option>
                    <option value="+382">+382</option>
                    <option value="+383">+383</option>
                    <option value="+385">+385</option>
                    <option value="+386">+386</option>
                    <option value="+387">+387</option>
                    <option value="+389">+389</option>
                    <option value="+420">+420</option>
                    <option value="+421">+421</option>
                    <option value="+423">+423</option>
                    <option value="+500">+500</option>
                    <option value="+501">+501</option>
                    <option value="+502">+502</option>
                    <option value="+503">+503</option>
                    <option value="+504">+504</option>
                    <option value="+505">+505</option>
                    <option value="+506">+506</option>
                    <option value="+507">+507</option>
                    <option value="+508">+508</option>
                    <option value="+509">+509</option>
                    <option value="+590">+590</option>
                    <option value="+591">+591</option>
                    <option value="+592">+592</option>
                    <option value="+593">+593</option>
                    <option value="+595">+595</option>
                    <option value="+597">+597</option>
                    <option value="+598">+598</option>
                    <option value="+599">+599</option>
                    <option value="+670">+670</option>
                    <option value="+672">+672</option>
                    <option value="+673">+673</option>
                    <option value="+674">+674</option>
                    <option value="+675">+675</option>
                    <option value="+676">+676</option>
                    <option value="+677">+677</option>
                    <option value="+678">+678</option>
                    <option value="+679">+679</option>
                    <option value="+680">+680</option>
                    <option value="+681">+681</option>
                    <option value="+682">+682</option>
                    <option value="+683">+683</option>
                    <option value="+685">+685</option>
                    <option value="+686">+686</option>
                    <option value="+687">+687</option>
                    <option value="+688">+688</option>
                    <option value="+689">+689</option>
                    <option value="+690">+690</option>
                    <option value="+691">+691</option>
                    <option value="+692">+692</option>
                    <option value="+850">+850</option>
                    <option value="+852">+852</option>
                    <option value="+853">+853</option>
                    <option value="+855">+855</option>
                    <option value="+856">+856</option>
                    <option value="+880">+880</option>
                    <option value="+886">+886</option>
                    <option value="+960">+960</option>
                    <option value="+961">+961</option>
                    <option value="+962">+962</option>
                    <option value="+963">+963</option>
                    <option value="+964">+964</option>
                    <option value="+965">+965</option>
                    <option value="+966">+966</option>
                    <option value="+967">+967</option>
                    <option value="+968">+968</option>
                    <option value="+970">+970</option>
                    <option value="+971">+971</option>
                    <option value="+972">+972</option>
                    <option value="+973">+973</option>
                    <option value="+974">+974</option>
                    <option value="+975">+975</option>
                    <option value="+976">+976</option>
                    <option value="+977">+977</option>
                    <option value="+992">+992</option>
                    <option value="+993">+993</option>
                    <option value="+994">+994</option>
                    <option value="+995">+995</option>
                    <option value="+996">+996</option>
                    <option value="+998">+998</option>
                    <option value="+-263">+-263</option>
                    <option value="+-267">+-267</option>
                    <option value="+-283">+-283</option>
                    <option value="+-339">+-339</option>
                    <option value="+-344">+-344</option>
                    <option value="+-440">+-440</option>
                    <option value="+-472">+-472</option>
                    <option value="+-648">+-648</option>
                    <option value="+-663">+-663</option>
                    <option value="+-669">+-669</option>
                    <option value="+-670">+-670</option>
                    <option value="+-683">+-683</option>
                    <option value="+-720">+-720</option>
                    <option value="+-757">+-757</option>
                    <option value="+-766">+-766</option>
                    <option value="+-783">+-783</option>
                    <option value="+-786">+-786</option>
                    <option value="+-808">+-808</option>
                    <option value="+-867">+-867</option>
                    <option value="+-868">+-868</option>
                    <option value="+-875">+-875</option>
                    <option value="+-1437">+-1437</option>
                    <option value="+-1490">+-1490</option>
                    <option value="+-1580">+-1580</option>
                    <option value="+-938">+-938</option>
                    <option value="+-828">+-828</option>
                    <option value="+-848">+-848</option>
                  </select>
                </div>
                <div className="col-md-9 col-lg-10 col-xs-8  pad-l0">
                  <input pattern="^[0-9]+$" value={phone} onChange={(e) => setPhone(onChangeTagInputNumber(e.target.value))} id="phone" maxlength="40" name="phone" size="20" type="text" placeholder="Phone*" />
                </div>
                <div className="col-md-12 col-md-6 col-xs-12">
                  <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" maxlength="80" name="email" size="20" type="email" placeholder="Email*" />
                </div>
                <input id="company" maxlength="40" name="company" size="20" type="hidden" value="" />
                {/* <!-- Lead Source --> */}
                <input id="00NC5000000WkkK" name="00NC5000000WkkK" type="hidden" value="Website" />
                {/* <!-- Lead Sub Source --> */}
                <input id="00NC5000000WkkS" name="00NC5000000WkkS" value="" type="hidden" />
                {/* <!-- Project Name --> */}
                {/* <!-- <input  id="00NC5000000WkkM" maxlength="255" name="00NC5000000WkkM" size="20" type="hidden" value="Luma" /> --> */}
                <div className="col-md-12 col-md-6 col-xs-12">
                  <select value={project} onChange={(e) => setProject(e.target.value)} id="00NC5000000WkkM" name="00NC5000000WkkM" title="Country Code">
                    <option value="Empty">Select Project*</option>
                    <option value="Parkwoods">Parkwoods</option>
                    <option value="Luma">Luma</option>
                    <option value="Divum">Divum</option>
                    <option value="Avanya">Avanya</option>
                    <option value="Astrum">Astrum</option>
                    <option value="Aldeiadegoa">Aldeia Goa</option>
                    <option value="Srishtinamaah">Srishti Namaah</option>
                    <option value="Raet">Raet</option>
                    <option value="Residencies">101 Residencies</option>

                  </select>
                </div>
                {/* <!-- Campaign --> */}
                <input id="00NC5000000WkkH" maxlength="255" name="00NC5000000WkkH" size="20" type="hidden" value="" />
                {/* <!-- Lead Stage --> */}
                <input id="00NC5000000WkkL" maxlength="255" name="00NC5000000WkkL" size="20" type="hidden" value="" />
                {/* <!-- Date and Time of Created Date --> */}
                <input id="00NC5000000WkkJ" name="00NC5000000WkkJ" size="20" type="hidden" value="" />
                {/* <!-- UTM Compaign --> */}
                <input id="00NC5000000WkkO" maxlength="255" name="00NC5000000WkkO" size="20" type="hidden" value="<?php echo $utm_campaign; ?>" />
                {/* <!-- UTM Medium --> */}
                <input id="00NC5000000WkkP" maxlength="255" name="00NC5000000WkkP" size="20" type="hidden" value="<?php echo $utm_medium; ?>" />
                {/* <!-- UTM Parameters --> */}
                <input id="00NC5000000WkkQ" maxlength="255" name="00NC5000000WkkQ" size="20" type="hidden" value="<?php echo $utm_term; ?>" />
                {/* <!-- UTM Source --> */}
                <input id="00NC5000000WkkR" maxlength="255" name="00NC5000000WkkR" size="20" type="hidden" value="<?php echo $utm_source; ?>" />
                <div className="col-md-12">
                  <input className="am-button af-primary btn_primary" type="submit" name="submit" />
                  {/* <ButtonPrimary text="Submit" /> */}
                </div>
              </div>

              <div className="col-md-12">
                <div className="agree-txt radio_group_parents">
                  <input type="checkbox" id="tnc" name="tnc" />
                  <label for="tnc">
                    <span> I agree and authorise to call/ send SMS/ Promotional/ Informational messages/notifications. This will override the registry with DNC/NDNC.</span>
                  </label>
                </div>
              </div>

            </form>
            <div id="msg-error"></div>
            <div id="msg-success"></div>

          </div>
        </div>
      </Layout>
    </section>
  );
};

export default Contact;
