import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/raet/hero/Hero';
import BottomNav from '../../components/common/raet/bottomNav/BottomNav';
import Landmark from '../../components/common/raet/landmark/Landmark';
import Info from '../../components/common/raet/info/Info';
import Experience from '../../components/common/raet/experience/Experience';
// import Features from '../../components/common/raet/features/Features';
import Gallery from '../../components/common/raet/gallery/Gallery';
import Walkthrough from '../../components/common/raet/walkthrough/Walkthrough';
// import VirtualTour from '../../components/common/raet/virtualTour/VirtualTour';
// import Exterior from '../../components/common/raet/exterior/Exterior';
import FloorPlan from '../../components/common/raet/floorPlan/FloorPlan';
import Barcode from '../../components/common/raet/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";





const RaetPage = () => {
  return (
    <>
      <Helmet>
        <title>Seafacing Penthouses & Apartments In Goa | 4 BHK Residences & 7 BHK Penthouses In Goa | Raet - Dynamix Group</title>
        <meta name="description" content="Explore ultra-luxury residences by Dynamix Group offering 4 BHK Residences & 7 BHK Penthouses near Bambolim Beach, Goa. Enjoy coastal living with sea-facing homes featuring private balconies." />
        <meta property="og:title" content="Seafacing Penthouses & Apartments In Goa | 4 BHK Residences & 7 BHK Penthouses In Goa | Raet" />
        <meta property="og:site_name" content="Dynamix Group" />
        <meta property="og:url" content="https://www.dynamixgroup.co.in/current-projects/raet" />
        <meta property="og:description" content="Explore ultra-luxury residences by Dynamix Group offering 4 BHK Residences & 7 BHK Penthouses near Bambolim Beach, Goa. Enjoy coastal living with sea-facing homes featuring private balconies." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.dynamixgroup.co.in/current-projects/raet#gallery-5" />

        <meta name='robots' content='noindex, nofollow' />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      {/* <Features /> */}
      <Gallery />
      <Walkthrough />
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default RaetPage;
