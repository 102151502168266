import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import QRcode from '../../../../assets/pdp/srishtiqr/Shristi-Wing-G-Rera-Qr.png';


import Parkwoodpdf1 from '../../../../assets/resource-pdf/parkwoods/environmental-clearance-noc.pdf'
import Parkwoodpdf2 from '../../../../assets/resource-pdf/parkwoods/environmental-clearance-noc.pdf';

// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>Srishti Namaah is registered as Sector 2A - WING E with MAHARERA Regn. <br/> No. P51700053089 For details please refer to <a href="https://maharera.mahaonline.gov.in." target="_blank" rel="noopener"> https://maharera.mahaonline.gov.in</a></p>
                    {/* <p>MahaRERA Registration No. P51800018784.</p> */}

                </div>
                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={QRcode} alt="plan" />
                        {/* <span>Avanya Tower A</span> */}
                    </div>
                    {/* <div className='barcode'>
                        <img src={QRcode} alt="plan" />
                        <span>Avanya Tower B</span>
                    </div> */}
                </div>
            </div>
    </Layout>

    </section>

  );
};

export default Barcode;
